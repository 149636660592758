import React from 'react';
import logoUrl from '../assets/logo.jpg';
import { navigate } from "gatsby-link";

const TemplateContent = ({redirect}) => {

    setTimeout(() => {
        typeof window !== 'undefined' && navigate(redirect);
    }, 500);


    return (
        <>
            <div className="redirect-template">
                <div className="logo">
                <img src={logoUrl} alt="logo" />
                </div>
                <h2 style={{ marginTop: "26px" }}>Loading....</h2>
            </div>
        </>
    )
}

export default TemplateContent;